<template>
  <div class="product-wrap case-wrap">
    <Header is-active="case" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-case"></div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="case-box">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <div class="ce-item" @click="handleGo('/case-a')">
                <div class="max-img">
                  <img src="../../assets/img/case/pic_1.jpg" alt="案例">
                </div>
                <h6>某污水处理厂自动化系统集成项目</h6>
                <div class="txt-box">
                  本工程为某区污水处理工程。污水厂近期规模为5万m/天，远期规模为10万m/天。主要内容为粗格栅及提升泵池、细格栅及沉砂池、絮……
                </div>
                <div class="more"> 查看详情 >></div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <div class="ce-item" @click="handleGo('/case-b')">
                <div class="max-img">
                  <img src="../../assets/img/case/pic_2.jpg" alt="案例">
                </div>
                <h6>某水务集团智慧水务平台项目</h6>
                <div class="txt-box">
                  智慧水务平台建设包括业务应用系统、支撑平台、信息系统集成三部分。业务应用系统：包括SCADA生产调度系统、管网GIS系统、巡检……
                </div>
                <div class="more">查看详情 >></div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <div class="ce-item" @click="handleGo('/case-c')">
                <div class="max-img">
                  <img src="../../assets/img/case/pic_3.jpg" alt="案例">
                </div>
                <h6>某制造企业数字化工厂项目</h6>
                <div class="txt-box">
                  工厂焊装、涂装、总装车间设备运行状态由人工监控，设备维修处理人工微信等方式报修，设备报警等 依靠对讲机呼叫，事后进行异……
                </div>
                <div class="more">查看详情 >></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <BackTop></BackTop>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '案例精品合集,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控精品案例合集,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  name: 'Case',
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },

  methods: {
    handleGo (way) {
      this.$router.push({ path: way })
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";
@import "../../assets/css/ing.less";

.case-wrap {
  .banner-case {
    background: url("../../assets/img/case/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }

  .case-box {
    width: 100%;
    height: auto;

    .ce-item {
      margin: 0 4% 4%;
      background: #FFFFFF;
      box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.16);
      font-family: Microsoft YaHei;
      cursor: pointer;

      h6 {
        text-align: left;
        font-size: 20px;
        font-weight: 400;
        color: #1890FF;
        line-height: 2;
        text-indent: 1em;
      }

      .txt-box {
        padding: 4%;
      }

      .more {
        text-align: right;
        padding-bottom: 6%;
        padding-right: 4%;
      }
    }

    .ce-item:hover {

      .txt-box {
        text-decoration: underline;
      }

      .more {
        text-decoration: underline;
      }
    }
  }
}

</style>
